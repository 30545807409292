<template>
  <!-- 电召下单详情 -->
  <div
    :class="{ 'phoneOrderDetial-page': from == 'ticketChange' }"
    class="phoneOrderDetial"
  >
    <div v-if="from != 'ticketChange'" class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div :class="{ 'info-page': from == 'ticketChange' }" class="info">
      <el-form
        ref="ruleForm"
        :rules="rules"
        :model="form"
        label-width="95px"
        label-position="right"
        :hide-required-asterisk="
          (hideStar || from == 'ticketChange') && step == 0
        "
      >
        <div class="shift">
          <p
            :class="{
              focus: focus == 1 && from !== 'ticketChange',
              'focus-box': from == 'ticketChange',
            }"
          >
            班次信息
          </p>
          <div class="form">
            <div class="first">
              <div>
                <el-form-item prop="startStation" label="出发地： ">
                  <el-select
                    v-model="form.startStation"
                    :disabled="disabled || from == 'ticketChange'"
                    clearable
                    :class="{ hidedown: hideStar || from == 'ticketChange' }"
                    placeholder="请选择出发地"
                    @focus="focus = 1"
                    @change="selectStart"
                  >
                    <el-option
                      v-for="item in startList"
                      :key="item.stationName"
                      :label="item.stationName"
                      :value="item.stationName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item prop="endStation" label="到达地：">
                  <el-select
                    v-model="form.endStation"
                    :disabled="disabled || from == 'ticketChange'"
                    clearable
                    :class="{ hidedown: hideStar || from == 'ticketChange' }"
                    placeholder="请选择到达地"
                    @focus="focus = 1"
                    @change="selectEnd"
                  >
                    <el-option
                      v-for="item in endList"
                      :key="item.stationName"
                      :label="item.stationName"
                      :value="item.stationName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="first">
              <div>
                <el-form-item label="出行日期：" prop="departureDate">
                  <el-date-picker
                    v-model="form.departureDate"
                    type="date"
                    :disabled="
                      (disabled || from == 'ticketChange') && step == 0
                    "
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                    @focus="focus = 1"
                    @change="form.shiftIntercityId = ''"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  prop="shiftIntercityId"
                  label="班次时间： "
                  class="shiftIntercityId-box"
                >
                  <el-select
                    v-model="form.shiftIntercityId"
                    :disabled="
                      (disabled || from == 'ticketChange') && step == 0
                    "
                    clearable
                    :class="{
                      hidedown:
                        (hideStar || from == 'ticketChange') && step == 0,
                    }"
                    placeholder="请选择班次时间"
                    @focus="selectShift"
                    @change="confirmShift"
                  >
                    <el-option
                      v-for="item in shiftList"
                      :key="item.shiftIntercityId"
                      :disabled="item.disabled"
                      :label="item.label"
                      :value="item.shiftIntercityId"
                    ></el-option>
                  </el-select>
                  <span
                    v-if="
                      from == 'ticketChange' &&
                      children < ticketChangeForm.carryingChildrenNum &&
                      lineId
                    "
                    style="color: red"
                    >此班次剩余携童票{{ children }}张，乘客携童数{{
                      ticketChangeForm.carryingChildrenNum
                    }}，无法改签</span
                  >
                  <span
                    v-if="
                      from !== 'ticketChange' &&
                      form.shiftIntercityId &&
                      !hideStar
                    "
                    >此班次剩余携童票
                    <span style="color: red">{{ children }}</span> 张</span
                  >
                </el-form-item>
              </div>
              <div v-if="form.shiftType === 1">
                <el-form-item label="出行时间：" prop="anticipationRidingTime">
                  <el-select
                    v-model="form.anticipationRidingTime"
                    :disabled="
                      (disabled || from == 'ticketChange') && step == 0
                    "
                    :class="{
                      hidedown:
                        (hideStar || from == 'ticketChange') && step == 0,
                    }"
                    @change="$forceUpdate()"
                  >
                    <el-option
                      v-for="single in anticipationRidingTimeList"
                      :key="single.value"
                      :label="single.label"
                      :value="single.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="visitor">
          <p
            :class="{
              focus: focus == 2 && from !== 'ticketChange',
              'focus-box': from == 'ticketChange',
            }"
          >
            乘客信息
          </p>
          <div class="form">
            <div class="first">
              <div>
                <el-form-item label="联系人电话：" prop="orderPhone">
                  <el-input
                    v-model="form.orderPhone"
                    :disabled="
                      (disabled || from == 'ticketChange') && step == 0
                    "
                    type="number"
                    maxlength="11"
                    placeholder="请输入联系人电话"
                    @focus="focus = 2"
                  />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="下单人电话：" prop="passengerPhone">
                  <el-input
                    v-model="form.passengerPhone"
                    :disabled="disabled || from == 'ticketChange'"
                    type="number"
                    @focus="focus = 2"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="first">
              <div style="width: 343px">
                <el-form-item required label="购票类型：">
                  <span
                    v-if="!hideStar && from !== 'ticketChange'"
                    class="special"
                  >
                    <el-radio
                      v-model="form.isRealName"
                      label="1"
                      @focus="focus = 2"
                      >实名</el-radio
                    >
                    <el-radio
                      v-model="form.isRealName"
                      label="0"
                      @focus="focus = 2"
                      >非实名</el-radio
                    >
                  </span>
                  <span v-else style="margin-left: 15px">
                    {{ form.isRealName == 0 ? "非实名" : "实名" }}
                  </span>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  v-if="from == 'ticketChange'"
                  required
                  label="合计金额："
                >
                  <span>
                    {{
                      (
                        ticketChangeForm.ticketPricesPer *
                        ticketChangeForm.validPassengersNum
                      ).toFixed(2)
                    }}
                  </span>
                </el-form-item>
              </div>
            </div>
            <div class="first">
              <div style="width: 343px">
                <el-form-item
                  v-if="form.isRealName == '0'"
                  required
                  label="乘客人数："
                >
                  <el-input-number
                    v-if="!hideStar && from !== 'ticketChange'"
                    v-model="form.passengerCount"
                    size="mini"
                    :min="1"
                    @change="selectValPer"
                  ></el-input-number>
                  <span v-else style="margin-left: 15px">
                    {{
                      from === "ticketChange"
                        ? ticketChangeForm.validPassengersNum
                        : form.passengerCount
                    }}
                  </span>
                </el-form-item>
                <header v-if="showTip" style="color: red; margin-left: 15px">
                  若要继续减少乘客人数，请先减少携童数
                </header>
              </div>
              <div>
                <el-form-item
                  v-if="
                    !hideStar &&
                    form.isRealName == '0' &&
                    from !== 'ticketChange'
                  "
                  label="携童数："
                >
                  <el-input-number
                    v-model="form.carryingChildrenNum"
                    size="mini"
                    :min="0"
                    :max="
                      form.passengerCount >= children
                        ? children
                        : form.passengerCount
                    "
                    @change="selectValChildren"
                  ></el-input-number>
                </el-form-item>
                <el-form-item
                  v-if="
                    (hideStar && form.isRealName == '0') ||
                    from == 'ticketChange'
                  "
                  required
                  label="携童数："
                >
                  <span>
                    {{
                      from === "ticketChange"
                        ? ticketChangeForm.carryingChildrenNum
                        : form.carryingChildrenNum
                    }}
                  </span>
                </el-form-item>
              </div>
            </div>
            <!-- 实名制乘客信息 -->
            <div v-if="form.isRealName == '1'">
              <div
                v-for="(item, index) in form.childOrderVOS"
                :key="index"
                class="second"
              >
                <el-form-item
                  :label="index == 0 ? '乘客：' : ''"
                  :prop="'childOrderVOS.' + index + '.userName'"
                  :rules="{
                    required: true,
                    message: '请输入乘客姓名',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.userName"
                    :disabled="disabled || from == 'ticketChange'"
                    :style="{ maxWidth: hideStar ? '80px' : '120px' }"
                    placeholder="乘客姓名"
                    @focus="focus = 2"
                  />
                </el-form-item>
                <el-form-item
                  :prop="'childOrderVOS.' + index + '.idCardNo'"
                  label-width="20px"
                  :rules="{
                    required: true,
                    validator: (rule, value, callback) => {
                      checkIdentificationNumber(rule, value, callback);
                    },
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.idCardNo"
                    :disabled="disabled || from == 'ticketChange'"
                    :style="{
                      width:
                        hideStar || from == 'ticketChange' ? '180px' : '310px',
                    }"
                    placeholder="乘客身份证号"
                    @focus="focus = 2"
                  />
                </el-form-item>
                <el-form-item
                  :prop="'childOrderVOS.' + index + '.segment'"
                  label-width="20px"
                  :rules="{
                    required: true,
                    message: '请选择乘客类型',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    v-model="item.segment"
                    :disabled="disabled || from == 'ticketChange'"
                    placeholder="请选择"
                    class="oinput1"
                    :class="{ hidedown: hideStar || from == 'ticketChange' }"
                    @focus="focus = 2"
                    @change="(e) => selectSegment(e, item)"
                  >
                    <el-option
                      v-for="e in personType"
                      :key="e.id"
                      :label="e.label"
                      :value="e.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="item.segment" label-width="20px">
                  <el-checkbox
                    v-model="item.isCarryingChildren"
                    :disabled="disabled || from == 'ticketChange'"
                    @change="clickChildren(index)"
                    >携童</el-checkbox
                  >
                </el-form-item>
                <i
                  v-if="!hideStar && from !== 'ticketChange'"
                  class="iconfont its_gy_delete"
                  @click="deleteVisitor(index)"
                ></i>
              </div>
              <div
                v-if="!hideStar && from !== 'ticketChange'"
                class="add"
                @click="addNew"
              >
                <i class="iconfont its_tianjia" style="margin-right: 10px"></i>
                添加新乘客
              </div>
            </div>
          </div>
        </div>
        <div class="pick">
          <p
            :class="{
              focus: focus == 3 && from !== 'ticketChange',
              'focus-box': from == 'ticketChange',
            }"
          >
            接送点
          </p>
          <div class="form">
            <el-form-item prop="startAddress" label="上车点：">
              <i
                v-if="(hideStar || from == 'ticketChange') && step == 0"
                class="iconfont its_gy_map"
              ></i>
              <el-input
                v-model="form.startAddress"
                :readonly="true"
                :disabled="(disabled || from == 'ticketChange') && step == 0"
                placeholder="请选择上车点"
              />
              <i
                v-if="(!hideStar && from !== 'ticketChange') || step == 1"
                class="iconfont its_gy_map"
                @click="selectAddress(0)"
              ></i>
            </el-form-item>
            <el-form-item prop="endAddress" label="下车点：">
              <i
                v-if="(hideStar || from == 'ticketChange') && step == 0"
                class="iconfont its_gy_map"
              ></i>
              <el-input
                v-model="form.endAddress"
                :readonly="true"
                :disabled="(disabled || from == 'ticketChange') && step == 0"
                placeholder="请选择下车点"
              />
              <i
                v-if="(!hideStar && from !== 'ticketChange') || step == 1"
                class="iconfont its_gy_map"
                @click="selectAddress(1)"
              ></i>
            </el-form-item>
            <el-form-item
              v-if="from == 'ticketChange' && step == 1"
              label="订单金额："
              class="order-amount"
            >
              <span style="color: red; font-size: 24px; font-weight: 600"
                >￥{{
                  (price + upFloatingPrice + downFloatingPrice).toFixed(2) *
                  ticketChangeForm.validPassengersNum
                }}</span
              >
              <span
                v-if="
                  orderAmountFlag &&
                  (price + upFloatingPrice + downFloatingPrice) *
                    ticketChangeForm.validPassengersNum !=
                    ticketChangeForm.ticketPricesPer *
                      ticketChangeForm.validPassengersNum
                "
                style="color: red"
                >（改签后订单金额不一致）</span
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div
      v-if="from !== 'ticketChange'"
      class="money"
      :class="{
        money1: payStatus == 1,
        money2: payStatus == 2,
        money3: payStatus == 3,
      }"
    >
      <ul>
        <li>
          <span>票价：</span>
          <span>{{ ticketPrice }}元/张</span>
        </li>
        <li>
          <span>服务费：</span>
          <span>{{ form.servicePricesPer }}元/张</span>
        </li>
        <li>
          <span>乘坐人数：</span>
          <span>{{ form.passengerCount }}</span>
        </li>
      </ul>
      <div class="paymoney">
        <span>应付金额：</span>
        <span class="howmuch">￥{{ form.totalPrices }}</span>
      </div>
      <div
        class="flag"
        :class="{
          flag1: payStatus == 1,
          flag2: payStatus == 2,
          flag3: payStatus == 3,
        }"
      >
        {{ payText }}
      </div>
    </div>
    <el-button
      v-if="payStatus == -1 && from !== 'ticketChange'"
      type="primary"
      @click="placeOrder"
      >立即下单</el-button
    >
    <div v-if="payStatus == 2" class="footer">
      <p>
        <i class="el-icon-info"></i>
        <span>请在</span>
        <span class="time">{{ lockTime }}</span>
        <span>内完成支付，逾期将自动取消订单</span>
      </p>
      <el-button type="primary" @click="payOrder">立即支付</el-button>
      <el-button
        v-if="$store.state.user.socketStatus"
        type="default"
        class="cancel"
        @click="cancelOrder"
        >取消订单</el-button
      >
    </div>
    <el-dialog
      v-if="dialogVisible1"
      :title="areaType ? '选择下车点' : '选择上车点'"
      :visible.sync="dialogVisible1"
      width="1000px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="cancelBtn"
    >
      <div class="map">
        <div class="searchbox">
          <header>
            <input v-model="searchInput" type="text" placeholder="搜索地址" />
            <el-button type="primary" size="small">搜索</el-button>
          </header>
          <div class="pointlist">
            <p
              v-for="(item, index) in addressList"
              :key="index"
              @click="searchClick(item, areaType)"
            >
              {{ item.address }}
            </p>
          </div>
        </div>
        <div class="select">
          <!-- <div v-if="areaMode">
            <header>请选择地点</header>
            <div class="point">
              <div
                v-for="(item, index) in pointList"
                :key="index"
                class="item"
                :class="{ item1: currentIndex === index }"
                @click="selectPoint(item, index)"
              >
                <span>{{ item.name }}</span>
                <span class="detial" :title="item.address">
                  {{ item.detialAddress.split("|")[1] }}
                </span>
              </div>
            </div>
          </div> -->
          <div v-if="!searchInput">
            <header>请选择地址</header>
            <div class="point">
              <div
                v-for="(item, index) in allStationList"
                :key="index"
                class="item"
                :class="{ item1: currentIndex === index }"
                @click="clickArea(item, index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div id="container" />
      </div>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="cancelBtn"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirm">选择</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="dialogVisible2"
      title="下单支付"
      :visible.sync="dialogVisible2"
      width="700px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="onDialogClose2"
    >
      <div v-if="!result" class="scanning">
        <header v-if="payStatus == 2 && !cover">
          <span>微信支付</span>
          <span>请在</span>
          <span>{{ scanningTime }}</span>
          <span>内扫码支付</span>
        </header>
        <div class="middle">
          <div class="qrcode">
            <div class="money">
              <span>支付金额：</span>
              <span>{{ form.totalPrices }}</span>
            </div>
            <!--二维码-->
            <div id="image" ref="qrcode"></div>
            <div class="transprant"></div>
            <div v-if="cover" class="cover">
              <button @click="reloadQR">二维码失效，点击重新获取二维码</button>
            </div>
            <div class="explain">
              <i class="iconfont its_gouxuan"></i>
              <span>请使用微信扫描二维码支付</span>
            </div>
          </div>
          <div class="weixin">
            <img src="~@/assets/images/home/scanning.png" alt />
          </div>
        </div>
      </div>
      <div v-if="result" class="result">
        <div v-if="result == '成功'" class="type">
          <img src="~@/assets/images/home/success.png" alt />
          <span>支付成功</span>
          <p>您已支付成功，请关闭窗口查看订单详情。</p>
        </div>
        <div v-else class="type">
          <img src="~@/assets/images/home/fail.png" alt />
          <span style="color: red">支付失败</span>
          <p>支付失败，请关闭窗口重新提交。</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {
  getPhoneOrderListAPI,
  searchLineAPI,
  searchShiftTimeAPI,
  placeOrderAPI,
  payOrderAPI,
  queryAreaList,
  configureParamsAPI,
  cancelOrderAPI,
  searchPointAPI,
  reschedulingAPI, //后台改签
} from "@/api/lib/api.js";
import { checkPhone } from "@/common/utils/index.js";
import AMap from "AMap";
import cryptoJs from "crypto-js";
import QRCode from "qrcodejs2";
export default {
  props: {
    from: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 0,
    },
    ticketChangeForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      hideStar: false,
      form: {
        businessType: 1,
        purchaseMethod: 1,
        shiftIntercityId: null,
        orderPhone: "",
        passengerCount: 1,
        startPointLongitude: null,
        startPointLatitude: null,
        endPointLongitude: null,
        endPointLatitude: null,
        startAddress: "",
        endAddress: "",
        startStation: "",
        endStation: "",
        departureDate: "",
        carryingChildrenNum: 0,
        childOrderVOS: [
          {
            userName: "",
            idCardNo: "",
            segment: "",
            isCarryingChildren: false,
          },
        ],
        passengerPhone: "",
        isRealName: "0",
        ticketPricesPer: 0,
        servicePricesPer: 0,
        validPassengersNum: 0,
        totalPrices: 0,
        endLineAreaId: null,
        anticipationRidingTime: "",
      },
      upFloatingPrice: 0,
      downFloatingPrice: 0,
      ticketPrice: 0,
      showPrice: true,
      personType: [
        {
          id: 0,
          label: "儿童",
        },
        {
          id: 1,
          label: "成人",
        },
      ],
      rules: {
        startStation: [
          { required: true, message: "请选择出发地", trigger: "change" },
        ],
        endStation: [
          { required: true, message: "请选择到达地", trigger: "change" },
        ],
        departureDate: [
          { required: true, message: "请选择出行日期", trigger: "blur" },
        ],
        anticipationRidingTime: [
          // { required: true, message: "请选择期望出行时间", trigger: "change" },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.form.anticipationRidingTime) {
                callback(new Error("请选择期望出行时间"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        shiftIntercityId: [
          {
            required: true,
            message: "请选择班次时间",
            trigger: "change",
          },
        ],
        orderPhone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              checkPhone(rule, value, callback);
            },
            trigger: "blur",
          },
        ],
        passengerPhone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              checkPhone(rule, value, callback);
            },
            trigger: "blur",
          },
        ],
        childOrderVOS: [{ userName: "", idCardNo: "", segment: "" }],
        startAddress: [
          { required: true, message: "请输入上车点", trigger: "blur" },
        ],
        endAddress: [
          { required: true, message: "请输入下车点", trigger: "blur" },
        ],
      },
      startList: [],
      endList: [],
      shiftList: [],
      dialogVisible1: false,
      dialogVisible2: false,
      disabled: false,
      dialogWidth: "",
      result: "",
      focus: -1,
      outTradeNo: "",
      map: null,
      pickerPosition: null,
      marker: "",
      areaPath: [],
      areaType: "",
      areaMode: null,
      positionLatLng: null,
      searchAddress: null,
      selectStartPoint: {
        //选择的上车点
        name: null,
        detialAddress: null,
        lng: null,
        lat: null,
        areaType: null,
        stationId: null,
        id: null,
        floatingPrice: 0,
        price: 0,
      },
      selectEndPoint: {
        //选择的下车点
        name: null,
        detialAddress: null,
        lng: null,
        lat: null,
        areaType: null,
        stationId: null,
        id: null,
        floatingPrice: 0,
        price: 0,
      },
      lineId: "",
      areaList: [],
      pointList: [],
      allStationList:[],//区域共存共同的点
      currentIndex: -1,
      searchInput: "",
      addressList: [],
      lockTime: "",
      scanningTime: "",
      qrcode: "",
      timer: "",
      timer2: "",
      cover: false,
      lock: 0,
      payText: "",
      payStatus: -1,
      onError: true,
      price: 0, //如果有中途点就是中途点的价格，没有就是票价
      shiftId: null,
      children: 0,
      showTip: false,
      anticipationRidingTimeList: [],
      currentShiftObj: null,
    };
  },
  computed: {
    //websocket发送消息获取支付状态
    orderStatus() {
      return this.$store.state.user.orderStatus;
    },
    orderAmountFlag() {
      if (this.children < this.ticketChangeForm.carryingChildrenNum)
        return true;
      else {
        return (
          (this.price + this.upFloatingPrice + this.downFloatingPrice).toFixed(
            2
          ) *
            this.ticketChangeForm.validPassengersNum !=
          this.ticketChangeForm.ticketPricesPer *
            this.ticketChangeForm.validPassengersNum
        );
      }
    },
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) {
          this.searchFun(val);
        } else {
          this.addressList = [];
        }
      },
    },
    orderStatus(val) {
      if (val.code === "SUCCESS") {
        this.result = "成功";
      } else {
        this.result = "失败";
      }
    },
    step(val) {
      if (val == 1) {
        this.form.departureDate = "";
        this.form.shiftIntercityId = "";
        this.form.anticipationRidingTime = "";
        this.anticipationRidingTimeList = [];
      }
    },
    orderAmountFlag(val) {
      this.$emit("watchOrderAmount", val);
    },
  },
  methods: {
    checkIdentificationNumber(rule, value, callback) {
      if (this.from == "ticketChange") {
        callback();
      }
      if (value && value !== "") {
        const regEn =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        const arr = this.form.childOrderVOS.filter((e) => e.idCardNo == value);
        if (!regEn.test(value)) {
          callback(new Error("请输入合法的身份证号码"));
        } else if (arr.length >= 2) {
          callback(new Error("乘客身份证号重复，请重新输入"));
        } else {
          callback();
        }
      } else {
        callback("请输入身份证号码");
      }
    },
    // 返回列表
    returnList() {
      this.$router.push("/projectTravel/phoneOrder");
    },
    // 选择出发地或者到达地
    selectLine(obj) {
      searchLineAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          obj.direction == 0
            ? (this.startList = res.data.list)
            : (this.endList = res.data.list);
          obj.direction == 0 && this.selectLine({ direction: 1 });
        }
      });
    },
    // 清空数据
    clearData() {
      this.form.startAddress = "";
      this.form.startPointLongitude = "";
      this.form.startPointLatitude = "";
      this.form.endAddress = "";
      this.form.endPointLongitude = "";
      this.form.endPointLatitude = "";
      this.form.shiftIntercityId = "";
      this.form.anticipationRidingTime = "";
      this.anticipationRidingTimeList = [];
    },
    // 选择出发地
    selectStart(val) {
      if (val) {
        this.selectLine({ direction: 1, chosenStation: val });
        this.clearData();
      } else {
        this.selectLine({ direction: 1 });
      }
    },
    // 选择到达地
    selectEnd(val) {
      if (val) {
        this.selectLine({ direction: 0, chosenStation: val });
        this.clearData();
      } else {
        this.selectLine({ direction: 0 });
      }
    },
    computedDepartureTime(item) {
      let timeShowValue = item.startDepartureTime;
      if (item.shiftType === 1) {
        timeShowValue = item.startDepartureTime + "-" + item.endDepartureTime;
      }
      return timeShowValue;
    },
    // 判断是否满足选班次时间的条件
    selectShift() {
      this.focus = 1;
      if (!this.form.startStation) {
        this.$message.warning("请选择出发地");
      } else if (!this.form.endStation) {
        this.$message.warning("请选择到达地");
      } else if (!this.form.departureDate) {
        this.$message.warning("请选择出行时间");
      } else {
        this.shiftList = [];
        searchShiftTimeAPI({
          startStation: this.form.startStation,
          endStation: this.form.endStation,
          departureDate: this.form.departureDate,
          shiftBefore:
            this.from === "ticketChange" ? (this.step == 0 ? 1 : 0) : 1,
          isSuspended: true,
          lineIds: this.lineId,
        })
          .then((res) => {
            if (res.code == "SUCCESS") {
              this.shiftList = res.data.filter((item) => {
                //判断是否有余票
                if (item.surplusTicket) {
                  //开始时间-结束时间
                  let timeShowValue = this.computedDepartureTime(item);
                  ////9.0.1 暂时过滤掉跨线路班次，跨线路班次不可改签
                  if (this.from === "ticketChange") {
                    item.label = this.hideStar
                      ? timeShowValue
                      : timeShowValue +
                        " - 余票" +
                        item.surplusTicket +
                        " - 票价" +
                        (item.discountTicketPrice
                          ? item.discountTicketPrice
                          : item.ticketPrice) +
                        " - 剩余携童票" +
                        item.surplusCarryingChildrenNum;
                    item.disabled =
                      item.shiftIntercityId ==
                      this.ticketChangeForm.shiftIntercityId;
                    return item;
                  } else {
                    item.label = this.hideStar
                      ? timeShowValue
                      : timeShowValue + " - 余票" + item.surplusTicket;
                    return item;
                  }
                }
              });
            }
          })
          .catch((res) => {
            // this.form.shiftIntercityId = "";
            // this.form.departureDate = "";
          });
      }
    },
    // 选择班次时间
    confirmShift(val) {
      if (val) {
        if (this.from != "ticketChange") {
          this.form.startAddress = "";
          this.form.endAddress = "";
          this.ticketPrice = 0;
          this.form.totalPrices = 0;
        }
        let pop = this.shiftList
          .filter((item) => item.shiftIntercityId == val)
          .pop();
        this.currentShiftObj = { ...pop };
        this.children = pop.surplusCarryingChildrenNum;
        this.lineId = pop.lineId;
        this.form.ticketPricesPer =
          pop?.discountTicketPrice || pop?.ticketPrice || "";
        this.price = this.form.ticketPricesPer;
        this.shiftId = val;
        const arr = [
          ...pop.lineAreaTimeTables,
          ...pop.lineAreaGetOffTables,
          ...pop.lineMidwayStations,
        ];
        this.form.shiftType = pop.shiftType;
        this.form.anticipationRidingTime = "";
        if (pop.shiftType === 1) {
          this.anticipationRidingTimeList = this.getHalfHourlyTimes(
            pop.startDepartureTime,
            pop.endDepartureTime
          );
        } else {
          this.anticipationRidingTimeList = [];
        }
        if (this.from === "ticketChange") {
          //判断是否是相同线路的
          if (this.lineId != this.ticketChangeForm.lineId) {
            this.$confirm("跨线路改签，需重新选择上下车点", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.form.startAddress = "";
                this.form.startPointLongitude = null;
                this.form.startPointLatitude = null;
                this.form.endAddress = "";
                this.form.endPointLongitude = null;
                this.form.endPointLatitude = null;
                this.form.endLineAreaId = null;
              })
              .catch(() => {
                this.form.shiftIntercityId = null;
                this.price = 0;
              });
          } else {
            let obj1 = arr.find(
              (e) => e.id == this.ticketChangeForm.startLineAreaId
            );
            let obj2 = arr.find(
              (e) => e.id == this.ticketChangeForm.endLineAreaId
            );
            if (obj1 && obj2) {
              this.upFloatingPrice = obj1.floatingPrice || 0;
              this.downFloatingPrice = obj2.floatingPrice || 0;
            } else if (obj1 && !this.ticketChangeForm.endLineAreaId) {
              this.upFloatingPrice = obj1.floatingPrice || 0;
              this.downFloatingPrice = 0;
              //过滤出来途经点站点信息获取价格
            const passStationObj = pop.lineMidwayStations?.find(i=>i.stationId === this.ticketChangeForm.endStationId);
            if(passStationObj) this.price = passStationObj?.price;
            } else {
              this.$message.warning("上下车点已被修改，请重新选择");
              this.form.startAddress = "";
              this.form.endAddress = "";
              this.price = 0;
              this.form.totalPrices = 0;
              this.upFloatingPrice = 0;
              this.downFloatingPrice = 0;
            }
          }
        } else {
          this.form.startAddress = "";
          this.form.endAddress = "";
          this.ticketPrice = 0;
          this.form.totalPrices = 0;
        }
        configureParamsAPI({lineId:this.lineId,channelNO: pop.channelNO}).then((res) => {
          if (res.code == "SUCCESS") {
            this.form.servicePricesPer = JSON.parse(
              res.data.SERVICE_FEE
            ).regulationValue;
            if (this.from != "ticketChange") {
              if (!arr.some((e) => e.floatingPrice)) {
                this.showPrice = true;
                this.upFloatingPrice = 0;
                this.downFloatingPrice = 0;
                this.computeTotal();
              } else {
                this.showPrice = false;
              }
            } else {
              this.computeTotal();
            }
          }
        });
      }
    },
    // 选择乘客人数
    selectValPer(cur, old) {
      if (cur < old && old == this.form.carryingChildrenNum) {
        this.showTip = true;
        this.$nextTick(() => {
          this.form.passengerCount = this.form.carryingChildrenNum;
        });
      }
      if (cur > old) this.showTip = false;
      this.computeTotal();
    },
    // 选择携童数
    selectValChildren(val) {
      this.form.carryingChildrenNum = val;
      if (this.form.carryingChildrenNum < this.form.passengerCount)
        this.showTip = false;
    },
    selectSegment(val, item) {
      if (!val && item.isCarryingChildren) {
        item.isCarryingChildren = false;
      }
    },
    clickChildren(index) {
      let total = 0;
      this.form.childOrderVOS.map((e) => {
        e.isCarryingChildren && (total += 1);
      });
      if (total > this.children) {
        this.$alert(`携童票超出本班次剩余携童数！`, "提示", {
          confirmButtonText: "确定",
          center: true,
          callback: () => {
            this.form.childOrderVOS[index].isCarryingChildren = false;
          },
        });
      }
    },
    // 添加新乘客
    addNew() {
      let obj = {
        userName: "",
        idCardNo: "",
        segment: "",
      };
      this.form.childOrderVOS.push(obj);
      this.form.passengerCount = this.form.childOrderVOS.length;
      this.computeTotal();
    },
    // 删除乘客
    deleteVisitor(index) {
      this.form.childOrderVOS.splice(index, 1);
      this.form.passengerCount = this.form.childOrderVOS.length;
      this.computeTotal();
    },
    getHalfHourlyTimes(startTime, endTime) {
      let y = new Date(this.form.departureDate).getFullYear();
      let m = new Date(this.form.departureDate).getMonth() + 1;
      let d = new Date(this.form.departureDate).getDate();
      let start = new Date(y + "/" + m + "/" + d + " " + startTime);
      let end = new Date(y + "/" + m + "/" + d + " " + endTime);
      let list = [];
      function formatTime(time) {
        //时间为个位数则补零
        return time < 10 ? "0" + time : time;
      }
      let len2 = (end.getTime() - start.getTime()) / (30 * 60 * 1000); //计算各子区间以半小时为间隔拆分后的数组长度
      for (let j = 0; j < len2; j++) {
        if (start.getTime() + 30 * 60 * 1000 <= end.getTime()) {
          //将各子区间日期按半小时递增转换为时间并存入list数组
          let ss = new Date(start.getTime() + 30 * 60 * 1000 * j);
          let flag = new Date(start.getTime() + 30 * 60 * 1000 * (j + 1)) > end;
          let ee = null;
          if (flag) {
            ee = end;
          } else {
            ee = new Date(start.getTime() + 30 * 60 * 1000 * (j + 1));
          }
          list.push({
            label: `${formatTime(ss.getHours())}:${formatTime(
              ss.getMinutes()
            )} - ${formatTime(ee.getHours())}:${formatTime(ee.getMinutes())}`,
            value: `${ss.getTime()}-${ee.getTime()}`,
          });
        }
      }
      return list;
    },
    renderData(id) {
      this.hideStar = true;
      this.disabled = true;
      const obj = {
        orderId: id,
        currentPage: 1,
        pageSize: 10,
      };
      getPhoneOrderListAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.form = res.data.list[0] || this.form;
          this.price = this.form.ticketPricesPer;
          this.ticketPrice = this.form.ticketPricesPer;
          this.form.shiftIntercityId =
            this.form.departureTime || this.form.shiftIntercityId;
          this.form.passengerCount =
            this.form.validPassengersNum || this.form.passengerCount;
          this.outTradeNo = this.form.orderId;
          if (this.form.orderStatus == 10) {
            this.payStatus = 2;
            this.payText = "待支付";
            if (!this.timer) {
              configureParamsAPI({ lineId: this.lineId }).then((res) => {
                if (res.code == "SUCCESS") {
                  this.lock = JSON.parse(
                    res.data.ORDER_LOCK_TIME
                  ).regulationValue;
                  this.countTime(this.form.createTime);
                }
              });
            }
          } else if (
            this.form.orderStatus >= 20 &&
            this.form.orderStatus <= 70
          ) {
            this.payStatus = 3;
            this.payText = "已完成";
          } else {
            this.payStatus = 1;
            this.payText = "已取消";
          }
          if (this.form.childOrderVOS) {
            this.form.childOrderVOS.forEach((e) => {
              e.idCardNo = this.decryptDes(e.idCardNo).replace(
                /^(.{4})(?:\d+)(.{3})$/,
                "$1******$2"
              );
              e.userName = e.passengerName;
              e.isCarryingChildren = e.carryingChildren ? true : false;
            });
          }
        }
      });
    },
    // 计算总价
    computeTotal() {
      let { servicePricesPer, passengerCount, childOrderVOS } = this.form;
      passengerCount = passengerCount || childOrderVOS.length;
      this.ticketPrice = (
        this.price +
        this.upFloatingPrice +
        this.downFloatingPrice
      ).toFixed(2);
      const total =
        (this.price +
          this.upFloatingPrice +
          this.downFloatingPrice +
          servicePricesPer) *
        passengerCount;
      this.form.totalPrices = total.toFixed(2);
    },
    // 立即下单按钮
    placeOrder() {
      if (this.from === "ticketChange") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$confirm("确定修改为新的班次吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                let params = {
                  orderId: this.form.id,
                  shiftIntercityId: this.form.shiftIntercityId,
                  startPointLongitude: this.form.startPointLongitude,
                  startPointLatitude: this.form.startPointLatitude,
                  endPointLongitude: this.form.endPointLongitude,
                  endPointLatitude: this.form.endPointLatitude,
                  startAddress: this.form.startAddress,
                  endAddress: this.form.endAddress,
                  passengerPhone: this.form.orderPhone,
                  startLineAreaId: this.form.startLineAreaId,
                  endLineAreaId: this.form.endLineAreaId || null,
                  totalPrices:
                    this.ticketChangeForm.ticketPricesPer *
                    this.ticketChangeForm.validPassengersNum,
                };
                //流水班需要添加期望出行时间
                if (this.form.shiftType === 1) {
                  params.startAnticipationRidingTime =
                    this.form.anticipationRidingTime?.split("-")[0];
                  params.endAnticipationRidingTime =
                    this.form.anticipationRidingTime?.split("-")[1];
                } else {
                  //定班：取班次时间
                  let timestamp = new Date(
                    this.currentShiftObj.startDepartureDateTime
                  ).getTime();
                  params.startAnticipationRidingTime = timestamp;
                  params.endAnticipationRidingTime = timestamp;
                }
                if (this.form.startStationId === null) {
                  params.startStationId = null;
                  params.startStationName = null;
                  params.endStationId = null;
                  params.endStationName = null;
                } else {
                  params.startStationId = this.form.startStationId;
                  params.startStationName = this.form.endStationName;
                  params.startStationType = this.form.startStationType;
                  params.endStationId = this.form.endStationId;
                  params.endStationName = this.form.endStationName;
                  params.endStationType = this.form.endStationType;
                }
                reschedulingAPI(params)
                  .then((res) => {
                    this.$emit("placeOrder", res.code);
                  })
                  .catch((res) => {
                    this.$emit("placeOrder", res.code);
                  });
              })
              .catch(() => {});
          }
        });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.computeTotal();
            let obj = {
              businessType: this.form.businessType,
              purchaseMethod: this.form.purchaseMethod,
              startPointLongitude: this.form.startPointLongitude,
              endPointLongitude: this.form.endPointLongitude,
              endPointLatitude: this.form.endPointLatitude,
              startPointLatitude: this.form.startPointLatitude,
              startAddress: this.form.startAddress,
              endAddress: this.form.endAddress,
              orderPhone: this.form.orderPhone,
              passengerPhone: this.form.passengerPhone,
              shiftIntercityId: this.form.shiftIntercityId,
              totalPrices: this.form.totalPrices,
              passengerCount: this.form.passengerCount,
              startStationId: this.form.startStationId,
              startLineAreaId: this.form.startLineAreaId,
              startStationType: this.form.startStationType,
              endStationId: this.form.endStationId,
              autoSeatSelection: true,
              endStationType: this.form.endStationType,
            };
            if (this.form.endLineAreaId)
              obj.endLineAreaId = this.form.endLineAreaId;
            if (this.form.endStationName)
              obj.endStationName = this.form.endStationName;
            if (this.form.isRealName == "0") {
              obj.realName = false;
              obj.carryingChildrenNum = this.form.carryingChildrenNum;
            } else {
              let childOrderVOS = this.deepClone(this.form.childOrderVOS);
              obj.realName = true;
              let total = 0;
              obj.usedPassengerVOS = childOrderVOS.map((item) => {
                item.idCardNo = this.encryptDes(item.idCardNo);
                item.isCarryingChildren && (total += 1);
                return item;
              });
              obj.carryingChildrenNum = total;
            }
            // 下单
            placeOrderAPI(obj).then((res) => {
              if (res.code == "SUCCESS") {
                this.outTradeNo = res.data;
                this.hideStar = true;
                this.disabled = true;
                this.payStatus = 2;
                this.payText = "待支付";
                this.$message.success("下单成功，请尽快支付");
                sessionStorage.setItem("orderId", res.data);
                configureParamsAPI({ lineId: this.lineId }).then((res) => {
                  if (res.code == "SUCCESS") {
                    this.lock = JSON.parse(
                      res.data.ORDER_LOCK_TIME
                    ).regulationValue;
                    // 倒计时
                    this.countTime(new Date());
                    // 打开下单支付弹窗
                    this.payOrder();
                  }
                });
              }
            });
          }
        });
      }
    },
    QRcodeTimer() {
      payOrderAPI({
        businessType: 1,
        outTradeNo: this.outTradeNo,
      }).then((result) => {
        if (result.code == "SUCCESS") {
          this.qrcode = result.data;
          this.$nextTick(() => {
            this.createQRCode(this.qrcode);
          });
        }
      });
    },
    // 立即支付按钮
    payOrder() {
      this.dialogVisible2 = true;
      this.QRcodeTimer();
      if (this.scanningTime == "00分60秒" || !this.scanningTime)
        this.scannCode();
    },
    // 生成扫码倒计时
    scannCode() {
      const createTime = new Date();
      this.scanningTime = `01分00秒`;
      this.timer2 = setInterval(() => {
        let newCreate = new Date();
        let totals = parseInt((newCreate - createTime) / 1000);
        let minutes = parseInt(totals / 60);
        let resultMinutes = "00";
        let resultSeconds = (minutes + 1) * 60 - totals;
        if (resultSeconds < 10) resultSeconds = "0" + resultSeconds;
        this.scanningTime = `${resultMinutes}分${resultSeconds}秒`;
        if (!(resultSeconds % 60)) {
          this.cover = true;
          resultSeconds = "00";
          clearInterval(this.timer2);
        }
      }, 1000);
    },
    // 生成支付倒计时
    countTime(obj) {
      const createTime = new Date(obj);
      this.timer = setInterval(() => {
        let newCreate = new Date();
        let totals = parseInt((newCreate - createTime) / 1000);
        let minutes = parseInt(totals / 60);
        let resultMinutes = this.lock - minutes - 1;
        if (resultMinutes < 10) resultMinutes = "0" + resultMinutes;
        let resultSeconds = (minutes + 1) * 60 - totals;
        if (resultSeconds < 10) resultSeconds = "0" + resultSeconds;
        if (!(resultSeconds % 60)) resultSeconds = "00";
        this.lockTime = `${resultMinutes}分${resultSeconds}秒`;
        this.renderData(this.outTradeNo);
        if (this.form.orderStatus >= 20 && this.form.orderStatus <= 70) {
          this.result = "成功";
          clearInterval(this.timer);
          if (this.timer2) clearInterval(this.timer2);
        }
        if (totals >= this.lock * 60) {
          this.dialogVisible2 = false;
          this.cancelOrder(); //如果倒计时结束，就调用取消订单接口
          clearInterval(this.timer);
          if (this.timer2) clearInterval(this.timer2);
        }
      }, 1000);
    },

    reloadQR() {
      this.cover = false;
      this.QRcodeTimer();
      this.scannCode();
    },

    // 生成二维码
    createQRCode(code) {
      this.$refs.qrcode.innerHTML = "";
      new QRCode(this.$refs.qrcode, {
        text: code, // 需要转换为二维码的内容
        width: 290,
        height: 290,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },

    // 取消订单
    cancelOrder() {
      if (this.timer) clearInterval(this.timer);
      if (this.timer2) clearInterval(this.timer2);
      cancelOrderAPI({ orderId: this.outTradeNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.payStatus = 1;
          this.lockTime = "";
          this.scanningTime = "";
          this.qrcode = "";
          this.payText = "已取消";
          this.$message("订单已取消");
        }
      });
    },
    // 地点选择
    selectAddress(type) {
      this.areaType = type;
      this.focus = 3;
      if (this.form.shiftIntercityId) {
        this.dialogVisible1 = true;
        this.$nextTick(() => {
          this.loadmap(type);
        });
      } else {
        this.$message.warning("请先选择班次时间");
      }
    },
    //赋值上车点
    pasteStartAddress() {
      this.form.startPointLatitude = this.selectStartPoint.lat;
      this.form.startPointLongitude = this.selectStartPoint.lng;
      this.form.startAddress = this.selectStartPoint.detialAddress;
      this.form.startStationId = this.selectStartPoint.stationId;
      if (this.selectStartPoint.areaType == 2) {
        this.form.startStationName = this.selectStartPoint.name;
      } else {
        this.form.startStationName = null;
      }
      this.form.startStationType = this.selectStartPoint.areaType;
      this.form.startLineAreaId = this.selectStartPoint.id;
      this.upFloatingPrice = this.selectStartPoint.floatingPrice;
    },
    //赋值下车点
    pasteEndAddress() {
      this.form.endPointLatitude = this.selectEndPoint.lat;
      this.form.endPointLongitude = this.selectEndPoint.lng;
      this.form.endAddress = this.selectEndPoint.detialAddress;
      this.form.endStationId = this.selectEndPoint.stationId;
      this.form.endStationType = this.selectEndPoint.areaType;
      if (this.selectEndPoint.areaType == 2) {
        this.form.endLineAreaId = null;
        this.form.endStationName = this.selectEndPoint.name;
        this.price = this.selectEndPoint.price;
      } else {
        this.form.endLineAreaId = this.selectEndPoint.id;
        this.form.endStationName = null;
        this.price = this.form.ticketPricesPer;
      }
      this.downFloatingPrice = this.selectEndPoint.floatingPrice;
    },
    // 确认上车点下车点
    confirm() {
      if (this.areaMode == 0) {
        let inRing = this.areaPath.findIndex((res) =>
          AMap.GeometryUtil.isPointInRing(this.positionLatLng, res)
        );
        if (inRing > -1) {
          if (this.areaType === 0) {
            this.pasteStartAddress();
            if (!this.showPrice) {
              this.form.endAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          } else {
            this.pasteEndAddress();
            if (!this.showPrice) {
              this.form.startAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          }
          this.onDialogClose1();
        } else {
          this.marker && this.marker.hide();
          this.$message.warning("该地点不在可选区域内！");
        }
      } else {
        if (this.positionLatLng) {
          if (this.areaType === 0) {
            this.pasteStartAddress();
            if (!this.showPrice) {
              this.form.endAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          } else {
            this.pasteEndAddress();
            if (!this.showPrice) {
              this.form.startAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          }
          this.onDialogClose1();
        } else {
          this.$message.warning("请选择固定点！");
        }
      }
      this.marker = null;
    },
    cancelBtn() {
      this.onDialogClose1();
    },
    // 关闭选择地点弹窗
    onDialogClose1() {
      this.dialogVisible1 = false;
      this.marker = null;
      this.areaPath = [];
      this.addressList = [];
      this.searchInput = null;
      this.searchAddress = null;
      this.positionLatLng = null;
      this.pointList = [];
      this.currentIndex = -1;
      if (this.map) this.map.destroy();
    },
    // 关闭支付弹窗
    onDialogClose2() {
      this.dialogVisible2 = false;
      this.cover = false;
    },
    // 实例化地图
    loadmap(type) {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.marker = null;
      const area = {
        lineId: this.lineId,
        areaType: type,
        shiftIntercityId: this.form.shiftIntercityId,
      };
      queryAreaList(area).then((res) => {
        if (res.code == "SUCCESS") {
          const data = res.data;
          let singleStation = [];
          // this.allStationList = data;//区域共存集合
          data.forEach((item) => {
            let arr = JSON.parse(item.areaContent);            
            if (item.areaMode == 0) {
              singleStation.push(item);
              // this.areaList = data;
              // this.areaMode = 0;
              let path = [];
              arr.forEach((e) => {
                path.push([e.lng, e.lat, item.id]);
                this.areaPath.push(path);
              });
              //初始化polygon
              var polygon = new AMap.Polygon({
                path: path,
                strokeColor: "#FF33FF",
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: "#1791fc",
                zIndex: 50,
              });
              this.map.add(polygon);
              // this.positionPicker(area.areaType, item);
            } else {
              // this.areaMode = 1;
              let obj = arr[0];
              let flag = new AMap.Marker({
                position: [obj.lng, obj.lat],
              });
              let markerTxt = new AMap.Text({
                text: item.name + "——" + item.detialAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: [obj.lng, obj.lat],
              });
              markerTxt.setMap(this.map);
              this.map.add(flag);
              // this.pointList.push({
              //   name: item.name,
              //   detialAddress: item.name + "|" + item.detialAddress,
              //   lng: obj.lng,
              //   lat: obj.lat,
              //   areaType: item.areaType,
              //   id: item.id,
              //   stationId: item.stationId,
              //   floatingPrice: item.floatingPrice,
              //   price: item.price,
              // });
              singleStation.push({
                name: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              })
            }
          });
          this.allStationList = [...singleStation];
          let position = null;
          if (type == 0) {
            position = this.selectStartPoint;            
          } else {
            position = this.selectEndPoint;
          }
          if (position.lng && position.lat) {
            // if (this.areaMode) {
            //   this.currentIndex = this.pointList.findIndex(
            //     (i) => i.id == position.id
            //   );
            // } else {
            //   this.currentIndex = this.areaList.findIndex(
            //     (i) => i.id == position.id
            //   );
            // }
            this.currentIndex = this.allStationList.findIndex(
                (i) => i.id == position.id
              );
            //回显
            const stationItem = this.allStationList.filter(item=>item.id == position.id)[0];
            if(this.areaMode == 0){
              this.positionPicker(type, stationItem);
            }else{
              if(this.pickerPosition){
                this.pickerPosition.stop();
                this.pickerPosition = null;
              }
            } 
            this.positionLatLng = [position.lng, position.lat];
            this.map.setCenter([position.lng, position.lat]);
          } else {
            this.map.setFitView();
          }
        }
      });
    },
    positionPicker(type, item) {
      let vm = this;
      this.map.on("drag", function () {
        vm.marker && vm.marker.hide();
      });
      this.map.on("dragend", function () {
        vm.marker && vm.marker.show();
      });
      const arr = JSON.parse(item.areaContent).map((i) => [i.lng, i.lat]);
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          iconStyle: {
            url: require("@/assets/images/home/from_icon2.png"),
            size: [30, 39],
            ancher: [15, 30], //锚点的位置，即被size缩放之后，图片的什么位置作为选中的位置
          },
          zIndex: 666,
        });
        vm.pickerPosition.on("success", function (positionResult) {
          const pos = positionResult.position;
          vm.positionLatLng = [pos.lng, pos.lat];
          new Promise((resolve, reject) => {
            //成功的时候调用resolve
            resolve(vm.regeoFun(pos));
            //失败的时候调用reject
            reject("error message");
          }).then((data) => {
            if (type == 0) {
              vm.selectStartPoint.lat = pos.lat;
              vm.selectStartPoint.lng = pos.lng;
              //处理成功后的逻辑
              vm.selectStartPoint.detialAddress = data;
              vm.searchAddress = data;
              vm.selectStartPoint.name = item.name;
              const inRing = AMap.GeometryUtil.isPointInRing(
                [pos.lng, pos.lat],
                arr
              );
              if (inRing) {
                vm.selectStartPoint.stationId = item.stationId;
                vm.selectStartPoint.areaType = item.areaType;
                vm.selectStartPoint.id = item.id;
                vm.selectStartPoint.price = item.price;
                vm.selectStartPoint.floatingPrice = item.floatingPrice;
              }
            } else {
              vm.selectEndPoint.lat = pos.lat;
              vm.selectEndPoint.lng = pos.lng;
              vm.selectEndPoint.detialAddress = data;
              vm.searchAddress = data;
              vm.selectEndPoint.name = item.name;
              const inRing = AMap.GeometryUtil.isPointInRing(
                [pos.lng, pos.lat],
                arr
              );
              if (inRing) {
                vm.selectEndPoint.stationId = item.stationId;
                vm.selectEndPoint.areaType = item.areaType;
                vm.selectEndPoint.floatingPrice = item.floatingPrice;
                vm.selectEndPoint.id = item.id;
                vm.selectEndPoint.price = item.price;
              }
            }
            if (!vm.marker) {
              vm.marker = new AMap.Text({
                text: vm.searchAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: vm.positionLatLng,
              });
              vm.marker.setMap(vm.map);
            } else {
              vm.marker.setPosition(vm.positionLatLng);
              vm.marker.setText(vm.searchAddress);
            }
          });
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    async regeoFun(position) {
      const res = await axios
        .get(
          `https://restapi.amap.com/v3/geocode/regeo?output=json&location=${position.lng},${position.lat}&key=d85b28ec7a166619a217cfe7cc0d700b&radius=1000&extensions=all`
        )
        .then((res) => {
          const addressComponent = res.data.regeocode.addressComponent;
          const abbreviation =
            res.data.regeocode.aois[0]?.name || addressComponent.district; //简称
          const detailedAddress =
            addressComponent.province +
            addressComponent.city +
            addressComponent.district +
            addressComponent.streetNumber.street +
            addressComponent.streetNumber.number; //详细地址
          return abbreviation + "|" + detailedAddress;
        });
      return res;
    },
    // 点击搜索的地址
    searchClick(item) {
      this.currentIndex = this.allStationList.findIndex(
        (i) => i.id == item.lineAreaId
      );
      const stationItem = this.allStationList.filter(el=>el.id == item.lineAreaId)[0];
      this.positionPicker(this.areaType, stationItem);
      this.positionLatLng = [item.wgLon, item.wgLat];
      this.map.setZoom(16);
      this.map.setCenter(this.positionLatLng);
      this.addressList = [];
      this.searchInput = null;
      this.searchAddress = item.address;
    },
    // 输入地名搜索地址
    searchFun(val) {
      const obj = {
        searchKey: val,
        lineId: this.lineId,
        areaType: this.areaType,
        shiftId: this.shiftId,
      };
      searchPointAPI(obj).then((res) => {
        this.addressList = res.data;
      });
    },
    clickArea(item, index) {
      this.areaMode = item.areaMode;
      this.currentIndex = index;
      console.log("ite,",item);
      if(item.areaMode == 0){
        this.positionPicker(this.areaType, item);
        let arr = JSON.parse(item.areaContent);
        let path = [];
        arr.forEach((e) => {
          path.push([e.lng, e.lat, item.id]);
        });
        var polygon = new AMap.Polygon({
          path,
          strokeColor: "#FF33FF",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: "#1791fc",
          zIndex: 50,
        });
        this.map.setFitView([polygon]);
      } else{
        if(this.pickerPosition){
          this.pickerPosition.stop();
          this.pickerPosition = null;
        }
        this.selectPoint(item, index);
      }
      
    },
    // 选择固定点
    selectPoint(item, index) {
      // this.currentIndex = index;
      this.positionLatLng = [item.lng, item.lat];
      this.map.setCenter(this.positionLatLng);
      if (this.areaType == 0) {
        this.selectStartPoint.lat = item.lat;
        this.selectStartPoint.lng = item.lng;
        this.selectStartPoint.detialAddress =
          // this.from === "ticketChange"?
          item.detialAddress;
        // : item.name + "(" + item.address + ")";
        this.selectStartPoint.stationId = item.stationId;
        this.selectStartPoint.name = item.name;
        this.selectStartPoint.id = item.id;
        this.selectStartPoint.areaType = item.areaType;
        this.selectStartPoint.floatingPrice = item.floatingPrice;
        this.selectStartPoint.price = item.price;
      } else {
        this.selectEndPoint.lat = item.lat;
        this.selectEndPoint.lng = item.lng;
        this.selectEndPoint.detialAddress =
          // this.from === "ticketChange"?
          item.detialAddress;
        // : item.name + "(" + item.address + ")";
        this.selectEndPoint.stationId = item.stationId;
        this.selectEndPoint.areaType = item.areaType;
        this.selectEndPoint.floatingPrice = item.floatingPrice;
        this.selectEndPoint.name = item.name;
        this.selectEndPoint.id = item.id;
        this.selectEndPoint.price = item.price;
      }
    },
    // des加密
    encryptDes(message, key = "inheritech_key") {
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
      const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
      return encrypted.toString(); // 返回hex格式密文，如需返回base64格式：encrypted.toString()
    },
    // des解密
    decryptDes(message, key = "inheritech_key") {
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    getTicketChangeFormFun() {
      this.form = JSON.parse(JSON.stringify(this.ticketChangeForm));
      this.lineId = this.form.lineId;

      this.form.childOrderVOS = this.form.intercityChildOrderVOS;
      this.form.shiftIntercityId = this.computedDepartureTime(this.form);
      this.form.childOrderVOS.forEach((item) => {
        item.idCardNo = this.decryptDes(item.idCardNo).replace(
          /^(.{4})(?:\d+)(.{3})$/,
          "$1******$2"
        );
        item.userName = item.passengerName;
        item.isCarryingChildren = item.carryingChildren ? true : false;
      });
      if (this.form.shiftType === 1) {
        this.anticipationRidingTimeList = this.getHalfHourlyTimes(
          this.form.startDepartureTime,
          this.form.endDepartureTime
        );
        this.form.anticipationRidingTime = `${this.form.startAnticipationRidingTime.substring(
          11,
          16
        )}-${this.form.endAnticipationRidingTime.substring(11, 16)}`;
      } else {
        this.anticipationRidingTimeList = [];
      }
    },
  },
  destroyed() {
    if (sessionStorage.getItem("orderId")) sessionStorage.removeItem("orderId");
    if (this.timer) clearInterval(this.timer);
    if (this.timer2) clearInterval(this.timer2);
  },
  created() {
    if (this.ticketChangeForm.id) {
      this.getTicketChangeFormFun();
    }
  },
  mounted() {
    let id = this.$route.query.id || sessionStorage.getItem("orderId");
    if (id) {
      this.payStatus = 0;
      this.renderData(id);
    } else {
      this.selectLine({ direction: 0 });
      this.hideStar = false;
      this.disabled = false;
    }
  },
};
</script>

<style lang="scss" scoped>
[v-clock] {
  display: none;
}
.phoneOrderDetial {
  height: 100%;
  width: 100%;
  overflow: scroll;
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  i {
    cursor: pointer;
  }
  .info-page {
    box-shadow: none !important;
    box-sizing: border-box;
    .el-form {
      margin: 0 150px;
    }
    .form {
      padding-right: 0 !important;
    }
  }
  .info {
    width: 100%;
    // max-height: 635px;
    padding-top: 20px;
    background-color: #fff;
    margin-top: 10px;
    box-sizing: border-box;
    // overflow: auto;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
    p {
      padding: 5px 21px;
      font-weight: bold;
      background-color: #f7f8f9;
      border-left: #333333 solid 2px;
    }
    .focus {
      background-color: #dbe2f3;
      border-left: #336ffe solid 2px;
      color: #336ffe;
    }
    .focus-box {
      border-left: #336ffe solid 2px;
    }
    .form {
      padding: 15px 62px;
    }
    .order-amount {
      ::v-deep .el-form-item__label {
        font-weight: 600;
      }
    }
    .hidedown {
      /deep/.el-input__suffix {
        display: none;
      }
    }
    .el-input {
      width: 202px;
    }
    .first {
      display: flex;
      div:nth-child(1) {
        margin-right: 30px;
      }
      .el-date-editor {
        font-size: 14px;
      }
    }
    .shiftIntercityId-box {
      .el-select {
        width: 210px !important;
      }
    }
    .shift {
      .el-select {
        width: 202px;
      }
    }
    .visitor {
      .second {
        display: flex;
        .oinput1 {
          max-width: 120px;
        }
        .its_gy_delete {
          color: red;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
      .add {
        width: 510px;
        height: 32px;
        border: #dcdedf dashed 1px;
        margin-bottom: 10px;
        cursor: pointer;
        margin-left: 95px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(131, 131, 131);
      }
      .special {
        margin-left: 10px;
        i {
          color: #336ffe;
          margin-right: 10px;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
    }
    .pick {
      .el-input {
        width: 590px;
        margin-right: 10px;
      }
      i {
        color: #336ffe;
        margin-left: 10px;
      }
    }
  }
  .money {
    padding: 0 10px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    ul {
      padding-left: 20px;
      display: flex;
      padding: 15px 20px;
      font-size: 14px;
      li {
        margin-right: 40px;
        color: rgb(131, 131, 131);
      }
    }
    .paymoney {
      padding-left: 20px;
      padding-bottom: 15px;
      font-weight: bold;
      margin-bottom: 10px;
      .howmuch {
        color: red;
        font-size: 24px;
      }
    }
    .flag {
      width: 200px;
      height: 72px;
      opacity: 1;
      color: white;
      position: absolute;
      bottom: 8px;
      right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      transform: rotate(50deg);
    }
    .flag1 {
      background-color: #d8d8d8;
    }
    .flag2 {
      background-color: #ff8627;
    }
    .flag3 {
      background-color: #2bc505;
    }
    .cover1 {
      position: absolute;
      right: 123px;
      bottom: 89px;
      width: 110px;
      height: 55px;
      background-color: #fff;
    }
    .cover2 {
      position: absolute;
      right: 32px;
      bottom: -55px;
      width: 110px;
      height: 55px;
      background-color: #f7f8f9;
    }
  }
  .money1 {
    background-color: rgba(216, 216, 216, 0.1);
  }
  .money2 {
    background-color: rgba(255, 134, 39, 0.1);
  }
  .money3 {
    background-color: rgba(43, 197, 5, 0.1);
  }
  .footer {
    font-size: 14px;
    p {
      margin-bottom: 6px;
      .time {
        color: red;
      }
      i {
        color: red;
        margin-right: 10px;
      }
    }
  }
  .map {
    position: relative;
    height: 500px;
    .searchbox {
      position: absolute;
      top: 14px;
      left: 10px;
      width: 300px;
      z-index: 1200;
      input {
        width: 212px;
        height: 28px;
        border: #d6d6d6 solid 1px;
        flex: 1;
        outline: none;
        margin-right: 16px;
        padding-left: 10px;
      }
      .pointlist {
        max-height: 250px;
        background-color: #fff;
        overflow: auto;
        margin-top: 10px;
        p {
          padding: 7px 10px;
          cursor: pointer;
          &:hover {
            background-color: #e4e4e4;
          }
        }
      }
    }
    .select {
      width: 312px;
      position: absolute;
      z-index: 1100;
      left: 10px;
      top: 55px;
      background-color: #fff;
      border-radius: 5px;
      max-height: 400px;
      overflow-y: auto;
      header {
        font-size: 15px;
        color: black;
        padding: 10px 10px 0;
      }
      p {
        margin-top: 10px;
        padding: 0 10px 10px;
      }
      .point {
        margin: 10px 0;
        .item {
          border-radius: 5px;
          cursor: pointer;
          padding: 10px 10px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          .detial {
            margin-left: 15px;
            font-size: 12px;
          }
        }
        .item1 {
          color: white;
          background-color: #336ffe;
        }
      }
    }
    #container {
      height: 500px;
    }
  }
  .scanning {
    padding: 20px;
    color: black;
    font-weight: 400;
    header {
      span {
        &:nth-child(1) {
          font-size: 16px;
          margin-right: 24px;
        }
        &:nth-child(3) {
          color: red;
        }
      }
    }
    .middle {
      display: flex;
      align-items: center;
      padding: 30px;
      justify-content: center;
      .qrcode {
        .money {
          text-align: center;
          margin-bottom: 5px;
          span {
            &:nth-child(2) {
              color: red;
              font-size: 20px;
            }
          }
        }
        #image {
          padding: 22px;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .transprant {
          width: 336px;
          height: 336px;
          background-color: transparent;
          margin-top: -336px;
          position: relative;
          z-index: 99;
        }
        .cover {
          width: 336px;
          height: 336px;
          background-color: rgba(0, 0, 0, 0.6);
          margin-top: -336px;
          color: white;
          display: flex;
          justify-content: center;
          position: relative;
          z-index: 999;
          align-items: center;
          button {
            background-color: #fff;
            border: none;
            outline: none;
            padding: 30px 15px;
            cursor: pointer;
          }
        }
        .explain {
          margin-top: 10px;
          width: 336px;
          height: 50px;
          background: rgba(0, 199, 119, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2bc505;
          font-size: 16px;
          i {
            margin-right: 20px;
          }
        }
      }
      .weixin {
        margin-left: 38px;
        img {
          width: 225px;
          height: 287px;
        }
      }
    }
  }
  .result {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .type {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: #2bc505;
        margin-bottom: 12px;
        font-size: 24px;
      }
    }
  }
}
.phoneOrderDetial-page {
  height: auto;
}
.el-form-item {
  margin-bottom: 13px;
}
/deep/.el-select .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border: none;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border: none;
  color: black;
}
/deep/.el-input--prefix .el-input__inner {
  padding-left: 15px;
}
/deep/.el-input__inner {
  line-height: 30px;
}
/deep/.el-icon-date:before {
  content: "";
}
</style>
