var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "phoneOrderDetial",
      class: { "phoneOrderDetial-page": _vm.from == "ticketChange" },
    },
    [
      _vm.from != "ticketChange"
        ? _c("div", { staticClass: "return", on: { click: _vm.returnList } }, [
            _c("i", { staticClass: "iconfont its_ba_fanhui" }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "info",
          class: { "info-page": _vm.from == "ticketChange" },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "95px",
                "label-position": "right",
                "hide-required-asterisk":
                  (_vm.hideStar || _vm.from == "ticketChange") && _vm.step == 0,
              },
            },
            [
              _c("div", { staticClass: "shift" }, [
                _c(
                  "p",
                  {
                    class: {
                      focus: _vm.focus == 1 && _vm.from !== "ticketChange",
                      "focus-box": _vm.from == "ticketChange",
                    },
                  },
                  [_vm._v(" 班次信息 ")]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "first" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "startStation", label: "出发地： " },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                class: {
                                  hidedown:
                                    _vm.hideStar || _vm.from == "ticketChange",
                                },
                                attrs: {
                                  disabled:
                                    _vm.disabled || _vm.from == "ticketChange",
                                  clearable: "",
                                  placeholder: "请选择出发地",
                                },
                                on: {
                                  focus: function ($event) {
                                    _vm.focus = 1
                                  },
                                  change: _vm.selectStart,
                                },
                                model: {
                                  value: _vm.form.startStation,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startStation", $$v)
                                  },
                                  expression: "form.startStation",
                                },
                              },
                              _vm._l(_vm.startList, function (item) {
                                return _c("el-option", {
                                  key: item.stationName,
                                  attrs: {
                                    label: item.stationName,
                                    value: item.stationName,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "endStation", label: "到达地：" } },
                          [
                            _c(
                              "el-select",
                              {
                                class: {
                                  hidedown:
                                    _vm.hideStar || _vm.from == "ticketChange",
                                },
                                attrs: {
                                  disabled:
                                    _vm.disabled || _vm.from == "ticketChange",
                                  clearable: "",
                                  placeholder: "请选择到达地",
                                },
                                on: {
                                  focus: function ($event) {
                                    _vm.focus = 1
                                  },
                                  change: _vm.selectEnd,
                                },
                                model: {
                                  value: _vm.form.endStation,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endStation", $$v)
                                  },
                                  expression: "form.endStation",
                                },
                              },
                              _vm._l(_vm.endList, function (item) {
                                return _c("el-option", {
                                  key: item.stationName,
                                  attrs: {
                                    label: item.stationName,
                                    value: item.stationName,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "first" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "出行日期：",
                              prop: "departureDate",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                disabled:
                                  (_vm.disabled ||
                                    _vm.from == "ticketChange") &&
                                  _vm.step == 0,
                                "value-format": "yyyy-MM-dd",
                                placeholder: "选择日期",
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.focus = 1
                                },
                                change: function ($event) {
                                  _vm.form.shiftIntercityId = ""
                                },
                              },
                              model: {
                                value: _vm.form.departureDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "departureDate", $$v)
                                },
                                expression: "form.departureDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "shiftIntercityId-box",
                            attrs: {
                              prop: "shiftIntercityId",
                              label: "班次时间： ",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                class: {
                                  hidedown:
                                    (_vm.hideStar ||
                                      _vm.from == "ticketChange") &&
                                    _vm.step == 0,
                                },
                                attrs: {
                                  disabled:
                                    (_vm.disabled ||
                                      _vm.from == "ticketChange") &&
                                    _vm.step == 0,
                                  clearable: "",
                                  placeholder: "请选择班次时间",
                                },
                                on: {
                                  focus: _vm.selectShift,
                                  change: _vm.confirmShift,
                                },
                                model: {
                                  value: _vm.form.shiftIntercityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shiftIntercityId", $$v)
                                  },
                                  expression: "form.shiftIntercityId",
                                },
                              },
                              _vm._l(_vm.shiftList, function (item) {
                                return _c("el-option", {
                                  key: item.shiftIntercityId,
                                  attrs: {
                                    disabled: item.disabled,
                                    label: item.label,
                                    value: item.shiftIntercityId,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm.from == "ticketChange" &&
                            _vm.children <
                              _vm.ticketChangeForm.carryingChildrenNum &&
                            _vm.lineId
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "此班次剩余携童票" +
                                      _vm._s(_vm.children) +
                                      "张，乘客携童数" +
                                      _vm._s(
                                        _vm.ticketChangeForm.carryingChildrenNum
                                      ) +
                                      "，无法改签"
                                  ),
                                ])
                              : _vm._e(),
                            _vm.from !== "ticketChange" &&
                            _vm.form.shiftIntercityId &&
                            !_vm.hideStar
                              ? _c("span", [
                                  _vm._v("此班次剩余携童票 "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v(_vm._s(_vm.children))]
                                  ),
                                  _vm._v(" 张"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.shiftType === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "出行时间：",
                                  prop: "anticipationRidingTime",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    class: {
                                      hidedown:
                                        (_vm.hideStar ||
                                          _vm.from == "ticketChange") &&
                                        _vm.step == 0,
                                    },
                                    attrs: {
                                      disabled:
                                        (_vm.disabled ||
                                          _vm.from == "ticketChange") &&
                                        _vm.step == 0,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                    model: {
                                      value: _vm.form.anticipationRidingTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "anticipationRidingTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.anticipationRidingTime",
                                    },
                                  },
                                  _vm._l(
                                    _vm.anticipationRidingTimeList,
                                    function (single) {
                                      return _c("el-option", {
                                        key: single.value,
                                        attrs: {
                                          label: single.label,
                                          value: single.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "visitor" }, [
                _c(
                  "p",
                  {
                    class: {
                      focus: _vm.focus == 2 && _vm.from !== "ticketChange",
                      "focus-box": _vm.from == "ticketChange",
                    },
                  },
                  [_vm._v(" 乘客信息 ")]
                ),
                _c("div", { staticClass: "form" }, [
                  _c("div", { staticClass: "first" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "联系人电话：",
                              prop: "orderPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled:
                                  (_vm.disabled ||
                                    _vm.from == "ticketChange") &&
                                  _vm.step == 0,
                                type: "number",
                                maxlength: "11",
                                placeholder: "请输入联系人电话",
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.focus = 2
                                },
                              },
                              model: {
                                value: _vm.form.orderPhone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderPhone", $$v)
                                },
                                expression: "form.orderPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "下单人电话：",
                              prop: "passengerPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled:
                                  _vm.disabled || _vm.from == "ticketChange",
                                type: "number",
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.focus = 2
                                },
                              },
                              model: {
                                value: _vm.form.passengerPhone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "passengerPhone", $$v)
                                },
                                expression: "form.passengerPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "first" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "343px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { required: "", label: "购票类型：" } },
                          [
                            !_vm.hideStar && _vm.from !== "ticketChange"
                              ? _c(
                                  "span",
                                  { staticClass: "special" },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        on: {
                                          focus: function ($event) {
                                            _vm.focus = 2
                                          },
                                        },
                                        model: {
                                          value: _vm.form.isRealName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "isRealName",
                                              $$v
                                            )
                                          },
                                          expression: "form.isRealName",
                                        },
                                      },
                                      [_vm._v("实名")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "0" },
                                        on: {
                                          focus: function ($event) {
                                            _vm.focus = 2
                                          },
                                        },
                                        model: {
                                          value: _vm.form.isRealName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "isRealName",
                                              $$v
                                            )
                                          },
                                          expression: "form.isRealName",
                                        },
                                      },
                                      [_vm._v("非实名")]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  { staticStyle: { "margin-left": "15px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.isRealName == 0
                                            ? "非实名"
                                            : "实名"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.from == "ticketChange"
                          ? _c(
                              "el-form-item",
                              { attrs: { required: "", label: "合计金额：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          _vm.ticketChangeForm.ticketPricesPer *
                                          _vm.ticketChangeForm
                                            .validPassengersNum
                                        ).toFixed(2)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "first" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "343px" } },
                      [
                        _vm.form.isRealName == "0"
                          ? _c(
                              "el-form-item",
                              { attrs: { required: "", label: "乘客人数：" } },
                              [
                                !_vm.hideStar && _vm.from !== "ticketChange"
                                  ? _c("el-input-number", {
                                      attrs: { size: "mini", min: 1 },
                                      on: { change: _vm.selectValPer },
                                      model: {
                                        value: _vm.form.passengerCount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "passengerCount",
                                            $$v
                                          )
                                        },
                                        expression: "form.passengerCount",
                                      },
                                    })
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "15px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.from === "ticketChange"
                                                ? _vm.ticketChangeForm
                                                    .validPassengersNum
                                                : _vm.form.passengerCount
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showTip
                          ? _c(
                              "header",
                              {
                                staticStyle: {
                                  color: "red",
                                  "margin-left": "15px",
                                },
                              },
                              [_vm._v(" 若要继续减少乘客人数，请先减少携童数 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        !_vm.hideStar &&
                        _vm.form.isRealName == "0" &&
                        _vm.from !== "ticketChange"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "携童数：" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    size: "mini",
                                    min: 0,
                                    max:
                                      _vm.form.passengerCount >= _vm.children
                                        ? _vm.children
                                        : _vm.form.passengerCount,
                                  },
                                  on: { change: _vm.selectValChildren },
                                  model: {
                                    value: _vm.form.carryingChildrenNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "carryingChildrenNum",
                                        $$v
                                      )
                                    },
                                    expression: "form.carryingChildrenNum",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        (_vm.hideStar && _vm.form.isRealName == "0") ||
                        _vm.from == "ticketChange"
                          ? _c(
                              "el-form-item",
                              { attrs: { required: "", label: "携童数：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.from === "ticketChange"
                                          ? _vm.ticketChangeForm
                                              .carryingChildrenNum
                                          : _vm.form.carryingChildrenNum
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.isRealName == "1"
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.form.childOrderVOS,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "second" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: index == 0 ? "乘客：" : "",
                                        prop:
                                          "childOrderVOS." +
                                          index +
                                          ".userName",
                                        rules: {
                                          required: true,
                                          message: "请输入乘客姓名",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        style: {
                                          maxWidth: _vm.hideStar
                                            ? "80px"
                                            : "120px",
                                        },
                                        attrs: {
                                          disabled:
                                            _vm.disabled ||
                                            _vm.from == "ticketChange",
                                          placeholder: "乘客姓名",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.focus = 2
                                          },
                                        },
                                        model: {
                                          value: item.userName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "userName", $$v)
                                          },
                                          expression: "item.userName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "childOrderVOS." +
                                          index +
                                          ".idCardNo",
                                        "label-width": "20px",
                                        rules: {
                                          required: true,
                                          validator: (
                                            rule,
                                            value,
                                            callback
                                          ) => {
                                            _vm.checkIdentificationNumber(
                                              rule,
                                              value,
                                              callback
                                            )
                                          },
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        style: {
                                          width:
                                            _vm.hideStar ||
                                            _vm.from == "ticketChange"
                                              ? "180px"
                                              : "310px",
                                        },
                                        attrs: {
                                          disabled:
                                            _vm.disabled ||
                                            _vm.from == "ticketChange",
                                          placeholder: "乘客身份证号",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.focus = 2
                                          },
                                        },
                                        model: {
                                          value: item.idCardNo,
                                          callback: function ($$v) {
                                            _vm.$set(item, "idCardNo", $$v)
                                          },
                                          expression: "item.idCardNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "childOrderVOS." + index + ".segment",
                                        "label-width": "20px",
                                        rules: {
                                          required: true,
                                          message: "请选择乘客类型",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "oinput1",
                                          class: {
                                            hidedown:
                                              _vm.hideStar ||
                                              _vm.from == "ticketChange",
                                          },
                                          attrs: {
                                            disabled:
                                              _vm.disabled ||
                                              _vm.from == "ticketChange",
                                            placeholder: "请选择",
                                          },
                                          on: {
                                            focus: function ($event) {
                                              _vm.focus = 2
                                            },
                                            change: (e) =>
                                              _vm.selectSegment(e, item),
                                          },
                                          model: {
                                            value: item.segment,
                                            callback: function ($$v) {
                                              _vm.$set(item, "segment", $$v)
                                            },
                                            expression: "item.segment",
                                          },
                                        },
                                        _vm._l(_vm.personType, function (e) {
                                          return _c("el-option", {
                                            key: e.id,
                                            attrs: {
                                              label: e.label,
                                              value: e.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.segment
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "20px" } },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.disabled ||
                                                  _vm.from == "ticketChange",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.clickChildren(
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.isCarryingChildren,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "isCarryingChildren",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.isCarryingChildren",
                                              },
                                            },
                                            [_vm._v("携童")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.hideStar && _vm.from !== "ticketChange"
                                    ? _c("i", {
                                        staticClass: "iconfont its_gy_delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteVisitor(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          !_vm.hideStar && _vm.from !== "ticketChange"
                            ? _c(
                                "div",
                                {
                                  staticClass: "add",
                                  on: { click: _vm.addNew },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont its_tianjia",
                                    staticStyle: { "margin-right": "10px" },
                                  }),
                                  _vm._v(" 添加新乘客 "),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "pick" }, [
                _c(
                  "p",
                  {
                    class: {
                      focus: _vm.focus == 3 && _vm.from !== "ticketChange",
                      "focus-box": _vm.from == "ticketChange",
                    },
                  },
                  [_vm._v(" 接送点 ")]
                ),
                _c(
                  "div",
                  { staticClass: "form" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "startAddress", label: "上车点：" } },
                      [
                        (_vm.hideStar || _vm.from == "ticketChange") &&
                        _vm.step == 0
                          ? _c("i", { staticClass: "iconfont its_gy_map" })
                          : _vm._e(),
                        _c("el-input", {
                          attrs: {
                            readonly: true,
                            disabled:
                              (_vm.disabled || _vm.from == "ticketChange") &&
                              _vm.step == 0,
                            placeholder: "请选择上车点",
                          },
                          model: {
                            value: _vm.form.startAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startAddress", $$v)
                            },
                            expression: "form.startAddress",
                          },
                        }),
                        (!_vm.hideStar && _vm.from !== "ticketChange") ||
                        _vm.step == 1
                          ? _c("i", {
                              staticClass: "iconfont its_gy_map",
                              on: {
                                click: function ($event) {
                                  return _vm.selectAddress(0)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "endAddress", label: "下车点：" } },
                      [
                        (_vm.hideStar || _vm.from == "ticketChange") &&
                        _vm.step == 0
                          ? _c("i", { staticClass: "iconfont its_gy_map" })
                          : _vm._e(),
                        _c("el-input", {
                          attrs: {
                            readonly: true,
                            disabled:
                              (_vm.disabled || _vm.from == "ticketChange") &&
                              _vm.step == 0,
                            placeholder: "请选择下车点",
                          },
                          model: {
                            value: _vm.form.endAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "endAddress", $$v)
                            },
                            expression: "form.endAddress",
                          },
                        }),
                        (!_vm.hideStar && _vm.from !== "ticketChange") ||
                        _vm.step == 1
                          ? _c("i", {
                              staticClass: "iconfont its_gy_map",
                              on: {
                                click: function ($event) {
                                  return _vm.selectAddress(1)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.from == "ticketChange" && _vm.step == 1
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "order-amount",
                            attrs: { label: "订单金额：" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-size": "24px",
                                  "font-weight": "600",
                                },
                              },
                              [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      (
                                        _vm.price +
                                        _vm.upFloatingPrice +
                                        _vm.downFloatingPrice
                                      ).toFixed(2) *
                                        _vm.ticketChangeForm.validPassengersNum
                                    )
                                ),
                              ]
                            ),
                            _vm.orderAmountFlag &&
                            (_vm.price +
                              _vm.upFloatingPrice +
                              _vm.downFloatingPrice) *
                              _vm.ticketChangeForm.validPassengersNum !=
                              _vm.ticketChangeForm.ticketPricesPer *
                                _vm.ticketChangeForm.validPassengersNum
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("（改签后订单金额不一致）"),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.from !== "ticketChange"
        ? _c(
            "div",
            {
              staticClass: "money",
              class: {
                money1: _vm.payStatus == 1,
                money2: _vm.payStatus == 2,
                money3: _vm.payStatus == 3,
              },
            },
            [
              _c("ul", [
                _c("li", [
                  _c("span", [_vm._v("票价：")]),
                  _c("span", [_vm._v(_vm._s(_vm.ticketPrice) + "元/张")]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("服务费：")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.form.servicePricesPer) + "元/张"),
                  ]),
                ]),
                _c("li", [
                  _c("span", [_vm._v("乘坐人数：")]),
                  _c("span", [_vm._v(_vm._s(_vm.form.passengerCount))]),
                ]),
              ]),
              _c("div", { staticClass: "paymoney" }, [
                _c("span", [_vm._v("应付金额：")]),
                _c("span", { staticClass: "howmuch" }, [
                  _vm._v("￥" + _vm._s(_vm.form.totalPrices)),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "flag",
                  class: {
                    flag1: _vm.payStatus == 1,
                    flag2: _vm.payStatus == 2,
                    flag3: _vm.payStatus == 3,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.payText) + " ")]
              ),
            ]
          )
        : _vm._e(),
      _vm.payStatus == -1 && _vm.from !== "ticketChange"
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.placeOrder } },
            [_vm._v("立即下单")]
          )
        : _vm._e(),
      _vm.payStatus == 2
        ? _c(
            "div",
            { staticClass: "footer" },
            [
              _c("p", [
                _c("i", { staticClass: "el-icon-info" }),
                _c("span", [_vm._v("请在")]),
                _c("span", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.lockTime)),
                ]),
                _c("span", [_vm._v("内完成支付，逾期将自动取消订单")]),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.payOrder } },
                [_vm._v("立即支付")]
              ),
              _vm.$store.state.user.socketStatus
                ? _c(
                    "el-button",
                    {
                      staticClass: "cancel",
                      attrs: { type: "default" },
                      on: { click: _vm.cancelOrder },
                    },
                    [_vm._v("取消订单")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.areaType ? "选择下车点" : "选择上车点",
                visible: _vm.dialogVisible1,
                width: "1000px",
                "close-on-click-modal": false,
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
                close: _vm.cancelBtn,
              },
            },
            [
              _c("div", { staticClass: "map" }, [
                _c("div", { staticClass: "searchbox" }, [
                  _c(
                    "header",
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchInput,
                            expression: "searchInput",
                          },
                        ],
                        attrs: { type: "text", placeholder: "搜索地址" },
                        domProps: { value: _vm.searchInput },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchInput = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "el-button",
                        { attrs: { type: "primary", size: "small" } },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pointlist" },
                    _vm._l(_vm.addressList, function (item, index) {
                      return _c(
                        "p",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.searchClick(item, _vm.areaType)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.address) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "select" }, [
                  !_vm.searchInput
                    ? _c("div", [
                        _c("header", [_vm._v("请选择地址")]),
                        _c(
                          "div",
                          { staticClass: "point" },
                          _vm._l(_vm.allStationList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item",
                                class: { item1: _vm.currentIndex === index },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickArea(item, index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { attrs: { id: "container" } }),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.cancelBtn },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "下单支付",
                visible: _vm.dialogVisible2,
                width: "700px",
                "close-on-click-modal": false,
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
                close: _vm.onDialogClose2,
              },
            },
            [
              !_vm.result
                ? _c("div", { staticClass: "scanning" }, [
                    _vm.payStatus == 2 && !_vm.cover
                      ? _c("header", [
                          _c("span", [_vm._v("微信支付")]),
                          _c("span", [_vm._v("请在")]),
                          _c("span", [_vm._v(_vm._s(_vm.scanningTime))]),
                          _c("span", [_vm._v("内扫码支付")]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "middle" }, [
                      _c("div", { staticClass: "qrcode" }, [
                        _c("div", { staticClass: "money" }, [
                          _c("span", [_vm._v("支付金额：")]),
                          _c("span", [_vm._v(_vm._s(_vm.form.totalPrices))]),
                        ]),
                        _c("div", { ref: "qrcode", attrs: { id: "image" } }),
                        _c("div", { staticClass: "transprant" }),
                        _vm.cover
                          ? _c("div", { staticClass: "cover" }, [
                              _c("button", { on: { click: _vm.reloadQR } }, [
                                _vm._v("二维码失效，点击重新获取二维码"),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "explain" }, [
                          _c("i", { staticClass: "iconfont its_gouxuan" }),
                          _c("span", [_vm._v("请使用微信扫描二维码支付")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "weixin" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/home/scanning.png"),
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.result
                ? _c("div", { staticClass: "result" }, [
                    _vm.result == "成功"
                      ? _c("div", { staticClass: "type" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/success.png"),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v("支付成功")]),
                          _c("p", [
                            _vm._v("您已支付成功，请关闭窗口查看订单详情。"),
                          ]),
                        ])
                      : _c("div", { staticClass: "type" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/fail.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("支付失败"),
                          ]),
                          _c("p", [_vm._v("支付失败，请关闭窗口重新提交。")]),
                        ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }